.countingContainer {
  margin: 25px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.counting {
  background: var(--light-wight);
  box-shadow: -10px -10px 20px #FCFCFC, 10px 10px 20px #E2E1E1;
  border-radius: 30px;
  height: 127px;
  max-width: 179px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.countingText {
  background-color: var(--blue-back);
  border-radius: 20px;
  box-shadow: -5px -5px 10px #FCFCFC, 5px 5px 10px #E2E1E1;
  height: 77px;
  width: 100%;
  max-width: 129px;
  display: grid;
  align-content: center;
}

.countingText p,
.countingText span {
  font-style: normal;
  text-align: center;
  background: linear-gradient(90deg, #E48E51 0%, #E8D48F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.countingText p {
  font-size: 24px;
  font-weight: 800;
  line-height: 22px;
  margin-bottom: 5px;
}

.countingText span {
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 13px;
}

@media only screen and (max-width: 413px) {
  .counting {
    max-width: 150px;
  }

  .countingText {
    max-width: 110px;
  }
}

@media only screen and (max-width: 355px) {
  .countingContainer {
    gap: 20px;
  }
}
