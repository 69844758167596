.formContainer {
  background-color: var(--form-background);
  padding: 29px 25px 33px;
  box-shadow: -10px -10px 20px #FCFCFC, 10px 10px 20px #E2E1E1;
  border-radius: 40px;
  margin-bottom: 35px;
  margin: 25px;
  max-width: 430px;
}

.formTitle {
  color: var(--blue-back);
  font-size: 24px;
  line-height: 22px;
  font-weight: 700;
}

.inputText {
  width: 100%;
  margin-top: 20px;
  padding: 18px 20px;
  background: var(--form-background);
  box-shadow: inset -3px -3px 6px #F5D895, inset 3px 3px 6px #D0B473;
  border-radius: 30px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.inputText::placeholder {
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #A5894A;
}

.formButtons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  cursor: pointer;
}

.formButtons div {
  background: #372F62;
  border-radius: 20px;
  padding: 5px 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: var(--form-background);
}

.infoList {
  margin-top: 18px;
}

.infoList .info {
  display: flex;
  gap: 8px;
}

.infoList .info .bullet {
  width: 10px;
  height: 10px;
  box-shadow: -3px -3px 6px #F5D895, 3px 3px 6px #D0B473;
  border-radius: 30px;
  background-color: var(--blue-back);
}

.infoList .info p {
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  color: #372F62;
}

@media only screen and (min-width: 430px) {
  .formContainer {
    margin: 25px auto;
  }
}

@media only screen and (max-width: 370px) {
  .infoList .info .bullet {
    width: 18px;
  }
}