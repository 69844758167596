:root {
  --blue-back: #372F62;
  --gray: #F5F5F5;
  --light-wight: #F2F2F2;
  --form-background: #E7C985;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: league-spartan;
}

@font-face {
  font-family: 'league-spartan';
  src: url('./Assets/fonts/league-spartan.ttf') format('truetype');
}

@font-face {
  font-family: "league-spartan";
  src: url("./Assets/fonts/league-spartan-bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "league-spartan";
  src: url("./Assets/fonts/league-spartan-extra-bold.ttf") format("truetype");
  font-weight: 800;
}

input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
}

*::-webkit-scrollbar {
  width: 0em;
  height: 0em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--gray);
}