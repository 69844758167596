.adContentDisplay {
  height: 513px;
  background-color: var(--blue-back);
  border-radius: 0px 0px 0px 40px;
  display: flex;
}

.imageContainer {
  display: flex;
  margin-top: 138px;
  overflow-x: auto;
}

.image {
  margin-right: 30px;
  border-radius: 40px;
  box-shadow: -10px -10px 20px #403672, 10px 10px 20px #2D2653;
  height: fit-content;
}

.image:first-child {
  margin-left: 25px;
}

.imageContainer img {
  height: 319px;
  border-radius: 40px;
}

.secondImage {
  margin-top: 15px;
}

.secondImage img {
  height: 282px;
}
